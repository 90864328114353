import { useState } from "react";
import { Upload, Button } from "antd";
import TextEditor from "components/shared/TextEditor/TextEditor";
import SendChat from "components/shared/Icon/SendChat";
import ChatInactive from "components/shared/Icon/ChatInactive";
import ChatActive from "components/shared/Icon/ChatActive";
import AddAttachment from "components/shared/Icon/AddAttachment";
import styles from "./labChat.module.scss";

const LabChatEditor = ({ onFileChange, editorInput, setEditorInput, sendHandler }) => {
  const [hideToolbar, setHideToolBar] = useState(true);

  const handleToolBar = () => {
    setHideToolBar((prev) => !prev);
  };
  const Icon = hideToolbar ? ChatInactive : ChatActive;

  return (
    <div className={styles["message-container"]}>
      <div className={styles["comment-editor"]}>
        <div className={styles["upload-icon"]}>
          <Upload
            multiple
            customRequest={() => {}}
            showUploadList={false}
            beforeUpload={onFileChange}
          >
            <Button
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              shape="circle"
              icon={<AddAttachment />}
            />
          </Upload>
        </div>
        <TextEditor
          imageCallback={onFileChange}
          placeholder="Write your message here"
          editorInput={editorInput}
          setEditorInput={setEditorInput}
          hideToolbar={hideToolbar}
        />
        <div className={styles["send-icons"]}>
          <div onClick={handleToolBar}>{<Icon style={{ fontSize: "1.5rem" }} />}</div>
          <div onClick={sendHandler}>
            <SendChat style={{ marginLeft: "10px", fontSize: "1.5rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabChatEditor;
