import { Card, message, Row, Image, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { DownloadIcon } from "components/shared/Icon/index";
import { CheckOutlined } from "@ant-design/icons";

import { downloadS3File, patientResourceDeleteApi } from "api/patient";
import UploadImage from "../uploadImageComponent";
import "../../../../assets/styles/components/progressImageComponent.scss";
import DeleteConfirmationModel from "components/shared/modals/deleteConfirmationModel";
import { getPatientResourceUploadStageApi } from "api/clinic";
import { getPreliminaryImagesApi } from "api/prescription";
import { formatImageView, sortPhotosByOrder } from "utils/progressImages";
const { Option } = Select;
const ProgressImagesNew = ({
  patientId,
  caseId,
  selectedCaseId,
}) => {
  const [isStageDropdownLoading, setIsStageDropdownLoading] = useState(true);
  const [isProgressImagesSpinning, setIsProgressImagesSpinning] = useState(false);
  const [alignerDropdownValues, setAlignerDropdownValues] = useState([]);
  const [selectedUploadStage, setSelectedUploadStage] = useState();
  const [selectedUploadStageData, setSelectedUploadStageData] = useState();
  const [allProgressImages, setAllProgressImages] = useState([]);
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null);
  const [currentTreatmentLabId, setCurrentTreatmentLabId] = useState(null);

  const [showImageDeleteConfirmationModel, setShowImageDeleteConfirmationModel] = useState(false);


  useEffect(() => {
    if (patientId && selectedCaseId) {
      getAlignerDropdownList()
    }
  }, [patientId, selectedCaseId])

  const getAlignerDropdownList = async () => {
    const params = { patientId };
    if (selectedCaseId) params.caseId = selectedCaseId;
    try {
      setIsStageDropdownLoading(true);
      setIsProgressImagesSpinning(true);

      const res = await getPatientResourceUploadStageApi(params);
      generateDropDownList(res?.body);
      setIsStageDropdownLoading(false);
      return res;
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsStageDropdownLoading(false);
    }
  };

  const generateDropDownList = (data) => {
    let isFirstCurrent = !data?.currentTreatmentId ? true : false;
    setCurrentTreatmentLabId(data?.currentTreatmentId);
    let options = [{
      label: <span className="progress-drd-item"><span>Preliminary Stage</span> <span>{isFirstCurrent ? <CheckOutlined /> : null}</span></span>,
      value: "100",
      data: data?.patient_image_upload_stages.find(obj => obj.patient_image_uploaded_stage_id == 100)
    }];
    if (data?.new_patient_image_upload_stages?.length) {
      const groupedData = data?.new_patient_image_upload_stages.reduce((acc, item) => {
        const { labTreatmentId } = item;

        // Initialize array for each labTreatmentId if not exists
        if (!acc[labTreatmentId]) {
          acc[labTreatmentId] = [];
        }

        // Add the current item to the respective group
        acc[labTreatmentId].push(item);

        return acc;
      }, {});

      for (const [key, value] of Object.entries(groupedData)) {
        let newOption = {
          label: value[0].revisionNo ? `Refinement Plan ${value[0].revisionNo}` : `Treatment Plan ${value[0].treatmentNo}`,
          options: []
        };
        value.forEach(obj => {
          let isCurrent = false;
          if (obj?.labTreatmentId == data?.currentTreatmentId && obj?.patient_image_uploaded_stage_id == data?.current_stage) {
            isCurrent = true;
          }
          newOption.options.push({
            label: <span className="progress-drd-item"><span>{obj.patient_image_uploaded_stage_name}</span> <span>{isCurrent ? <CheckOutlined /> : null}</span></span>,
            value: `${obj.patient_image_uploaded_stage_id}:${obj.labTreatmentId}`,
            data: obj
          })
        })
        options.push(newOption);
      }
    }
    setAlignerDropdownValues(options);
    if (data?.currentTreatmentId) {
      setSelectedUploadStage(`${data.current_stage}:${data.currentTreatmentId}`)
      setSelectedUploadStageData(data?.new_patient_image_upload_stages?.find(obj => obj.patient_image_uploaded_stage_id == data?.current_stage && obj.labTreatmentId == data?.currentTreatmentId))

    }
    else {
      setSelectedUploadStage(data?.current_stage?.toString())
      setSelectedUploadStageData(data?.patient_image_upload_stages.find(obj => obj.patient_image_uploaded_stage_id == 100))

    }
  }

  useEffect(() => {
    if (selectedUploadStageData) {
      getProgressImages(selectedUploadStageData)
    }
  }, [selectedUploadStageData])

  const getProgressImages = async (imageStageData = selectedUploadStageData) => {
    try {
      setIsProgressImagesSpinning(true)
      const request = {
        patientId,
        patientStage: "preliminary",
        alignerId: 100,
      }

      if (imageStageData?.patient_image_uploaded_stage_id == 100) {
        request.patientStage = "preliminary";
        request.alignerId = 100;
      }
      else if (imageStageData?.patient_image_uploaded_stage_id >= 1 && imageStageData?.patient_image_uploaded_stage_id <= 99) {
        request.patientStage = "aligner";
        request.alignerId = imageStageData?.patient_image_uploaded_stage_id;
        request.labTreatmentId = imageStageData?.labTreatmentId
      }

      const { data } = await getPreliminaryImagesApi(request)

      let patientNotUploadedImages = data?.body?.results?.progress_img_types_not_uploaded ? data?.body?.results?.progress_img_types_not_uploaded : [];
      const patientUploadedImages = data?.body?.results?.patient_onboarding_images ? data?.body?.results?.patient_onboarding_images : [];
      patientNotUploadedImages = patientNotUploadedImages.map(obj=>{
        if(imageStageData?.labTreatmentId){
          obj.lab_treatment_id = imageStageData?.labTreatmentId
        }
        return obj;
      })
      const combined = [...patientNotUploadedImages, ...patientUploadedImages];
      const finalData = sortPhotosByOrder(combined);
      setAllProgressImages(finalData);

    } catch (error) {
      console.log("error", error);
    }
    finally {
      setIsProgressImagesSpinning(false)
    }
  }

  const deleteProgressImage = async (resourceUrl) => {
    const hide = message.loading("Deleting image", 0);

    try {
      await patientResourceDeleteApi({
        patient_id: patientId,
        file_name: resourceUrl,
      })
      setTimeout(hide, 0);
      getProgressImages()
      message.success("Resource Deleted");
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to delete. Please try again later");
      }
    }
  };


  function checkUandL(str) {
    let hasU = false;
    let hasL = false;

    for (let char of str) {
      if (char === "U") {
        hasU = true;
      }
      if (char === "L") {
        hasL = true;
      }
    }

    return {
      hasU,
      hasL,
    };
  }


  const renderAlignerDropdown = () => {
    return (<Select
      value={selectedUploadStage}
      loading={isStageDropdownLoading}
      style={{
        width: 200,
      }}
      options={alignerDropdownValues}
      onSelect={onDropdownChange}
    />)
  }

  const onDropdownChange = (value, option) => {
    setSelectedUploadStage(value);
    setSelectedUploadStageData(option?.data)
  }



  const download = async (file) => {
    if (file?.imageDeleteRefUrl) {
      const fileName = file?.imageDeleteRefUrl?.split("/")[1];
      if (fileName) {
        window.open(downloadS3File(fileName));
      }
    }
  };

  const deleteImage = () => {
    deleteProgressImage(imageToBeDeleted.resource_url);
    setImageToBeDeleted(null);
  };

  return (
    <>
      {showImageDeleteConfirmationModel && imageToBeDeleted && (
        <DeleteConfirmationModel
          title="Delete Image"
          content={`(${imageToBeDeleted?.patient_image_view})`}
          showModal={showImageDeleteConfirmationModel}
          onConfirmation={deleteImage}
          setShow={setShowImageDeleteConfirmationModel}
          mode={"deleteImage"}
        ></DeleteConfirmationModel>
      )}
      <Spin spinning={isProgressImagesSpinning}>
        <Card
          title="Progress Images"
          extra={renderAlignerDropdown()}
          className={`progress-image-card`}
          style={{
            boxShadow: "0px 10px 16px rgba(0, 0, 0, 0.02)",
            borderRadius: "8px",
            minHeight: "54.2rem",
            overflowY: "auto",
          }}
        >


          <div className="preview-content">
            <div className="image-upload-container">
              {allProgressImages.map((item, i) => {
                const withOutAccessKeyImg = item?.url?.indexOf("?");
                const processedImg = item?.url?.slice(0, withOutAccessKeyImg);
                // if (stageId !== 100) {
                //   if (
                //     alignerName.hasL &&
                //     !alignerName.hasU &&
                //     item.patient_image_view === "TopTeeth"
                //   ) {
                //     return null;
                //   }

                //   if (
                //     !alignerName.hasL &&
                //     alignerName.hasU &&
                //     item.patient_image_view === "BottomTeeth"
                //   ) {
                //     return null;
                //   }
                // }

                let currentTreatment=false;
                if(item?.lab_treatment_id==currentTreatmentLabId && selectedUploadStageData?.patient_image_uploaded_stage_id!=100 ){
                  currentTreatment=true;
                }
  
                if(item?.lab_treatment_id!=currentTreatmentLabId && !item.url && selectedUploadStageData?.patient_image_uploaded_stage_id!=100){
                  return null;
                }

                return (
                  <div key={i}>
                    {item.url ? (
                      <>
                        <div
                          className="image-Card"
                        >
                          <Row style={{ marginBottom: "10px" }}>
                            <p style={{ marginBottom: "0px" }}>
                              {formatImageView(item.patient_image_view)}
                            </p>
                            {currentTreatment ?
                              <DeleteOutlined
                              className="del-icon"
                              onClick={() => {
                                setImageToBeDeleted(item);
                                setShowImageDeleteConfirmationModel(true);
                              }}
                            /> : null
                            }
                            
                            <DownloadIcon
                              marginLeft={"0.5rem"}
                              onClick={() => {
                                download(item);
                              }}
                            />
                          </Row>
                          <Image className="image-dim" src={processedImg} />
                        </div>
                      </>
                    ) : (
                      <div
                        className="image-Card"
                      >
                        <p>{formatImageView(item.patient_image_view)}</p>
                        <UploadImage
                          patientId={patientId}
                          viewType={item.patient_image_view}
                          caseId={caseId}
                          selectedUploadStageData={selectedUploadStageData}
                          selectedCaseId={selectedCaseId}
                          getProgressImages={getProgressImages}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Card>
      </Spin>
    </>

  );
};

export default ProgressImagesNew;
